import request from '@/utils/request'

export function search (params) {
  return request({
    url: '/log/transactions/search',
    method: 'post',
    params
  })
}

export function getPage (page, noitems, params) {
  return request({
    url: '/log/transactions/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getStatus () {
  return request({
    url: '/log/transactions/status',
    method: 'get'
  })
}

export function getTypes () {
  return request({
    url: '/log/transactions/type',
    method: 'get'
  })
}
