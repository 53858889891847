<template>
  <div class="app-container">
    <!-- <el-row class="margin-bottom-20">
      <el-col :lg="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="" v-model="filterForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
       <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
       </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          @click.stop.prevent="handleClean"
          size="mini"
          class="margin-left-10"
          type="warning"
          icon="el-icon-delete"
        >Limpiar</el-button>
      </el-col>
    </el-row> -->
    <el-row>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="12" :xs="12">
          <el-select v-model="filterForm.type" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Tipo">
            <el-option
              v-for="itemT in types"
              :key="itemT.type"
              :label="itemT.type"
              :value="itemT.type">
            </el-option>
          </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="12" :xs="12">
          <el-select v-model="filterForm.status" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Estatus">
            <el-option
              v-for="itemS in status"
              :key="itemS.status"
              :label="itemS.status"
              :value="itemS.status">
            </el-option>
          </el-select>
      </el-col>
      <el-col class="grid-content" align="center" :lg="8" :md="12" :sm="12" :xs="24">
          <span class=" margin-filter">Registros por fecha consultada</span>
          <el-date-picker
            v-model="filterDateConsultationRange"
            v-on:change="handleFilterDateConsultation()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
      </el-col>
      <el-col class="grid-content" align="center" :lg="8" :md="8" :sm="12" :xs="24">
          <span class=" margin-filter">Registros por fecha de creación</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
      </el-col>
      <el-col class="grid-content" align="center" :lg="24" :md="14" :sm="24" :xs="24" style="display: flex; justify-content: flex-end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
      </el-col>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      border
      height="600"
      fit
      highlight-current-row
      style="width: 100%">
      <el-table-column label="ID" width="100" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{scope.row._id}}</div>
            <span>{{ scope.row._id.substr(-4) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Tipo" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Estatus" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Transacciones obtenidas" width="110" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.extracted_transactions }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Transacciones guardadas" width="110" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.saved_transactions }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Día consultado"  width="130" align="center" >
        <template slot-scope="scope">
          {{ scope.row.day_of_consultation_text }}
        </template>
      </el-table-column>
      <el-table-column label="Tiempo inicio consulta"  width="160" align="center" >
        <template slot-scope="scope">
          {{ scope.row.start_time_consultation_text }}
        </template>
      </el-table-column>
      <el-table-column label="Tiempo finalización consulta"  width="190" align="center" >
        <template slot-scope="scope">
          {{ scope.row.end_time_consultation_text }}
        </template>
      </el-table-column>
      <el-table-column label="Duración consulta (seg)"  width="170" align="center" >
        <template slot-scope="scope">
          {{ scope.row.consultation_time_seg }}
        </template>
      </el-table-column>
      <el-table-column label="Fecha de creación"  width="170" align="center" >
        <template slot-scope="scope">
          {{ scope.row.created_at | formatDateTime }}
        </template>
      </el-table-column>
      <el-table-column label="Error"  width="200" align="center" >
        <template slot-scope="scope">
          {{ scope.row.error }}
        </template>
      </el-table-column>
      <el-table-column label="Código de error"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.errorCode }}
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
    <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
      :total="parseInt(totalPages)">
    </el-pagination>
  </el-row>
  </div>
</template>

<script>
import { search, getPage, getTypes, getStatus } from '@/api/logTransactions'

export default {
  data () {
    return {
      filterForm: {
        query: '',
        type: '',
        status: '',
        consultationStartRange: '',
        consultationEndRange: '',
        registerStartRange: '',
        registerEndRange: ''
      },
      types: [],
      status: [],
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      filterDateConsultationRange: '',
      filterDateRegisterRange: '',
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.filterForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.filterForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.filterForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
          console.log(response)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async getAllTypes () {
      await getTypes()
        .then((response) => {
          this.types = response.data
        })
    },
    async getAllStatus () {
      await getStatus()
        .then((response) => {
          this.status = response.data
        })
    },
    fetchData () {
      this.getAllTypes()
      this.getAllStatus()
      this.handleSearchFilter()
    },
    handleFilterDateConsultation () {
      if (this.filterDateConsultationRange) {
        this.filterForm.consultationStartRange = this.filterDateConsultationRange[0]
        this.filterForm.consultationEndRange = this.filterDateConsultationRange[1]
        this.handleSearchFilter()
      } else {
        this.filterForm.consultationStartRange = ''
        this.filterForm.consultationEndRange = ''
        this.handleSearchFilter()
      }
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.filterForm.registerStartRange = this.filterDateRegisterRange[0]
        this.filterForm.registerEndRange = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.filterForm.registerStartRange = ''
        this.filterForm.registerEndRange = ''
        this.handleSearchFilter()
      }
    },
    handleClean () {
      this.filterDateRegisterRange = []
      this.filterForm.query = ''
      this.filterForm.type = ''
      this.filterForm.status = ''
      this.filterForm.consultationStartRange = ''
      this.filterForm.consultationEndRange = ''
      this.filterForm.registerStartRange = ''
      this.filterForm.registerEndRange = ''
      this.handleSearchFilter()
    }
  }
}
</script>
